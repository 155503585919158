import store from '@/store'
import { lazyLoad } from '../index'
import DataRoutes from './data'
import BusinessRoutes from './business'

export default {
  path: '/admin',
  component: lazyLoad('Admin/AdminView'),
  meta: { requiresAuth: true },
  beforeEnter: (to, from, next) => {
    if (store.getters.isAdmin) { next() } else { next('/') }
  },
  children: [
    {
      path: 'xeroCallback',
      component: lazyLoad('Admin/AdminXeroCallback')
    },
    DataRoutes,
    BusinessRoutes,
    {
      path: 'xero',
      component: lazyLoad('Admin/Xero/layout'),
      redirect: 'xero/registrations',
      children: [
        {
          path: 'customers',
          component: lazyLoad('Admin/Xero/Customers')
        },
        {
          path: 'registrations',
          component: lazyLoad('Admin/Xero/Registrations')
        },
        {
          path: 'invoices',
          component: lazyLoad('Admin/Xero/Invoices')
        }
      ]
    }
  ]
}
